/*--------------------------------------------------------------
# Variables
--------------------------------------------------------------*/

$colour-teal: #6dc2b6;
$color-d-blue: #1629D4;

$colour-border: #303030;

$dark-grey:#333333;
$med-grey:#535353;
$off-med-grey: #5e5e5e;
$light-grey: #e8e8e8;
$off-white: #f9fafb;
$new-l-grey: #F5F5F5;
$white: #fff;
$black: #000;

$colour-font-default: $black;

$font-default: 'Open Sans', sans-serif;
$secondary-font: 'Arial', sans-serif;


$button-size: 50px;
$slide-editor-width: 290px;
$filmstrip-width: 170px;
$page-border-width: 4px;
/*--------------------------------------------------------------
# Mixins
--------------------------------------------------------------*/

@mixin transition($t){
  -webkit-transition: all $t ease-in-out;
  -moz-transition: all $t ease-in-out;
  -ms-transition: all $t ease-in-out;
  -o-transition: all $t ease-in-out;
  transition: all $t ease-in-out;
}

@function tint($color, $percentage) {
@return mix($color, white, $percentage);
}

@function shade($color, $percentage) {
@return mix($color, black, $percentage);
}

@mixin background-opacity($color, $opacity: 0.3) {
  background: $color; /* The Fallback */
  background: rgba($color, $opacity);
}

@mixin removescrollbar() {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  &::-webkit-scrollbar { /* WebKit */
      width: 0;
      height: 0;
  }
}

$breakpoints: (
'phone-u':   ( min-width:  576px ),
'tablet-u':  ( min-width:  768px ),
'desktop-u': ( min-width:  992px ),
'full-u':  ( min-width: 1200px ),
'phone-d':   ( max-width: 575.98px ),
'tablet-d':  ( max-width: 767.98px ),
'desktop-d': ( max-width: 991.98px ),
'full-d':  ( max-width: 1199.98px ),
);

@mixin breakpoint($name) {
    @if map-has-key($breakpoints, $name) {
      @media #{inspect(map-get($breakpoints, $name))} {
    @content;
  }
}
@else {
  @warn "Couldn't find a breakpoint named `#{$name}`.";
}
}

/*--------------------------------------------------------------
# Globals & Helpers
--------------------------------------------------------------*/

:root{
  --dark-grey: #{$dark-grey};
  --med-grey: #{$med-grey};
  --off-med-grey: #{$off-med-grey};
  --light-grey: #{$light-grey};
  --border-colour: #{$colour-border};
  --colour-teal: #{$colour-teal};
  --colour-teal-50: #{tint($colour-teal, 50%)};
}

html{
  font-size: 13px;
}

body{
  overflow-x: hidden;
  background: $light-grey;
  font-family: $font-default;
  color:$colour-font-default;
  font-size: 1rem;
  line-height: 1.2rem;
  font-weight: 600 !important;
  margin: 0;
  &.no-scroll{
    overflow: hidden;
  }
}

.app {
  text-align: center;
  overflow: hidden;
  height: 100vh;
  padding-bottom: $page-border-width;
  position: relative;
  &:after{
    position: absolute;
    content: '';
    width: 100%;
    height: $page-border-width;
    bottom: 0;
    left: 0;
    background-color: $color-d-blue;
    z-index: 3;
  }
  &:before{
    position: absolute;
    content: '';
    width: $page-border-width;
    height: 100%;
    top: 0;
    right: 0;
    background-color: $color-d-blue;
    z-index: 3;
  }
}

.page{
 background: $new-l-grey;
 width: calc(100% - #{$button-size} - #{$page-border-width});
 margin-left: $button-size;
 height: calc(100vh - #{$button-size} - #{$page-border-width});
 position: relative;
}

a{
  color: inherit;
  text-decoration: none !important;
  &:hover{
    color: inherit;
  }
}

button{
  background: $light-grey;
  border: 1px solid $colour-border;
}
/*--------------------------------------------------------------
# Typography
--------------------------------------------------------------*/

h1, .h1{
  margin: 0;
  font-size: 1.6rem;
  line-height: 1.6rem;
  font-weight: 800;
}

h2{
  margin: 0;
  font-size: 2rem;
  line-height: 100%;
  font-weight: 800;
}

h3{
  font-size: 1.43rem;
  line-height: 1.5rem;
}


/*--------------------------------------------------------------
# Navigation
--------------------------------------------------------------*/

.side-nav{
  background-color: $med-grey;
  height: calc(100vh - #{$button-size});
  width: $button-size + $page-border-width;
  position: fixed;
  left: 0;
  top: $button-size;
  border-right: 1px solid $colour-border;
  border-left: $page-border-width solid $color-d-blue;
  color: white;
  ul{
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li{
    text-align: center;
    font-size: 0.7rem !important;
    height: $button-size + ($page-border-width * 2);
    width: $button-size - $page-border-width;
    // border-bottom: 1px solid $colour-border;
    @include transition(0.2s);
    &:hover{
      @include background-opacity($off-med-grey, 0.8)
    }
    img{
      height: 18px;
      width: 18px;
      margin-bottom: 3px;
      object-fit: contain;
    }
  }
  a{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: column;
    padding: 5px;
    &.active{
      @include background-opacity($off-med-grey, 1)
    }
  }
  p{
    margin: 0;
  }
}

.head-nav{
  width: 100%;
  padding-left: $button-size;
  overflow: hidden;
  height: $button-size;
  background: $color-d-blue;
  color: $white;
  .row{
    height: calc(#{$button-size} - 1px);
  }
  h1{
    display: inline-block;
    padding: 10px;
    // margin-top: -6px;
    cursor: default;
    &.editable{
      @include transition(0.3s);
      transform: scale(1);
      cursor: pointer;
      &:hover{
        transform: scale(1.01);
      }
    }
  }
  .head-nav__home{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: column;
    padding: 5px;
    text-align: center;
    font-size: 0.7rem !important;
    height: 40px;
    width: 40px;
    @include transition(0.2s);
    position: absolute;
    top: $page-border-width;
    left: $page-border-width * 2;
    &:hover{
      @include background-opacity($off-med-grey, 0.2)
    }
    img{
      height: 18px;
      width: 18px;
      margin-bottom: 3px;
      object-fit: contain;
      // opacity: 0.8;
    }
    &.active{
      @include background-opacity($off-med-grey, 0.2)
    }
    p{
      margin: 0;
      display: none;
    }
  }
  .alfred-logo{
    height: $button-size * 0.4;
    position: absolute;
    top: $button-size * 0.25;
    right: $page-border-width * 3;
  }
}

.data-entry{
  position: absolute;
  background-color: $med-grey;
  right: 0;
  top: 0;
  width: $slide-editor-width;
  height: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  @include removescrollbar();
  h3{
    // height: $button-size;
    font-size: 0.8rem;
    padding: 8px;
    margin: 0;
    color: white;
    border-bottom: 1px solid $colour-border;
    font-weight: 700;
    text-align: left;
  }
  h4{
    // height: $button-size;
    font-size: 0.8rem;
    // padding: 8px;
    margin: 0;
    margin-bottom: 15px;
    color: white;
    // border-bottom: 1px solid $colour-border;
    font-weight: 700;
    text-align: left;
    opacity: 0.7;
  }
  .save-slide{
    margin: 0;
    display: block;
    width: 100%;
    height:$button-size;
    background: $colour-teal;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    @include transition(0.2s);
    &:hover{
      opacity: 0.7;
    }
  }
  .data-entry__layout{
    select{
      width: calc(100% - 20px);
      font-size: 0.8rem;
      margin: 10px auto;
      padding: 7px;
    }
  }
  .data-entry__footer{
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  textarea{
    resize: none;
    border: none;
    border-radius: 0;
    padding: 15px;
    height: 150px;
    width: 100%;
    line-height: 1.4rem;
    background-color: $light-grey;
    border: 1px solid $colour-border;
    border-top: 0;
  }
}

.data-entry__gallery{
  padding: 10px;
  border-bottom: 1px solid $colour-border;
}

.single-person{
  .single-person__options{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    font-size: 0.8rem;
    color: white;
    &>div{
      width: 100%;
      padding: 7px 0;
      @include background-opacity($dark-grey, 0.3);
      border-right: 1px solid $colour-border;
      cursor: pointer;
      &:last-child{
        border-right: none;
      }
      &.selected{
        background: $light-grey;
        color: $colour-font-default;
      }
    }
  }
  .single-person__gallery{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px 20px;
    margin: 15px 0;
    height: 350px;
    overflow: auto;
    background: white;
    @include removescrollbar();
    div{
        height: 65px;
        width: 65px;
        cursor: pointer;
        padding: 5px;
        position: relative;
        img{
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
        span{
          font-size: 0.7rem;
          line-height: 0.9rem;
          position: absolute;
          width: 100%;
          text-align: center;
          left: 0;
          bottom: 0;
        }
        &.selected, &:hover{
            border: 1px solid $colour-border;
        }
    }
  }
}

.photo-selector{
  .photo-selector__gallery{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px 20px;
    margin: 15px 0;
    height: 350px;
    overflow: auto;
    background: white;
    @include removescrollbar();
    div{
        height: 230px;
        width: 230px;
        cursor: pointer;
        padding: 5px;
        position: relative;
        margin-bottom: 15px;
        img{
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
        span{
          font-size: 0.7rem;
          line-height: 0.9rem;
          position: absolute;
          width: 100%;
          text-align: center;
          left: 0;
          bottom: 0;
        }
        &.selected, &:hover{
            border: 1px solid $colour-border;
        }
    }
  }
}

.object-selector{
  .object-selector__gallery{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px 20px;
    margin: 15px 0;
    height: 350px;
    overflow: auto;
    background: white;
    @include removescrollbar();
    div{
        height: 230px;
        width: 230px;
        cursor: pointer;
        padding: 5px;
        position: relative;
        margin-bottom: 15px;
        img{
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
        span{
          font-size: 0.7rem;
          line-height: 0.9rem;
          position: absolute;
          width: 100%;
          text-align: center;
          left: 0;
          bottom: 0;
        }
        &.selected, &:hover{
            border: 1px solid $colour-border;
        }
    }
  }
}

.people-selector{
  .people-selector__gallery{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px 20px;
    margin: 15px 0;
    height: 310px;
    overflow: auto;
    background: white;
    @include removescrollbar();
    div{
        height: 230px;
        width: 50%;
        cursor: pointer;
        padding: 5px;
        position: relative;
        margin-bottom: 15px;
        img{
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
        span{
          font-size: 0.7rem;
          line-height: 0.9rem;
          position: absolute;
          width: 100%;
          text-align: center;
          left: 0;
          bottom: 0;
        }
        &.selected, &:hover{
            border: 1px solid $colour-border;
        }
    }
  }
}

.slide-editor__wrap{
  height: 100%;
  width: 100%;
  position: relative;
}

.slide-editor__currentPage{
  height: 100%;
  width: 100%;
}

.slide-editor{
  height: 100%;
  width: calc(100% - #{$slide-editor-width} - #{$filmstrip-width});
  padding-bottom: $button-size;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-left: $filmstrip-width;
}

.editor-tools{
  position: fixed;
  bottom: 0;
  left: $page-border-width + $button-size - 3;
  width: $filmstrip-width - 2;
  height: $button-size;
  border-top: 1px solid $colour-border;
  background: $off-med-grey;
  display: flex;
  z-index: 2;
  img{
    height: 15px;
    width: 15px;
    margin: 5px 0;
    object-fit: contain;
  }
  button{
    width: 50%;
    height: 100%;
    padding: 10px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: column;
    border: none;
    background: $med-grey;
    text-align: center;
    color: white;
    font-size: 0.7rem !important;
    @include transition(0.2s);
    &:hover{
      @include background-opacity($off-med-grey, 0.8);
    }
  }
}

.loading-text{
  position: relative;
  z-index: 1;
  padding: 10px;
  &:before{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-color: $colour-teal;
    z-index: -1;
    animation-name: loading-bar;
    animation-iteration-count: infinite;
    animation-duration: 6s;
    transform-origin: left;
  }
}


@keyframes loading-bar {
  from {transform: scaleX(0);}
  to {transform: scaleX(1);}
}

.img-dropdown{
  font-size: 0.85rem !important;
  position: relative;
  border-bottom: 1px solid $colour-border;
  @include transition(0.4s);
  overflow: hidden;
  height: $button-size + 1px;
  .img-dropdown__heading{
    height: $button-size;
    cursor: pointer;
    *{
      pointer-events: none;
    }
    p{
      margin: 0;
      height: $button-size;
      line-height: $button-size;
    }
    img{
      height: 1rem;
      margin-right: 5px;
      margin-top: -2px;
    }
    @include transition(0.2s);
    &:hover{
      background-color: $med-grey;
    }
  }
  .img-dropdown__options{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    position: absolute;
    left: 0;
    top: $button-size;
    margin: 0;
    padding: 0 20px 20px;
    height: 240px;
    overflow: auto;
    transition: opacity 0.3s ease;
    visibility: hidden;
    opacity: 0;
    div{
        height: 65px;
        width: 65px;
        cursor: pointer;
        padding: 5px;
        position: relative;
        img{
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
        span{
          font-size: 0.7rem;
          line-height: 0.9rem;
          position: absolute;
          width: 100%;
          text-align: center;
          left: 0;
          bottom: 0;
        }
        &.selected, &:hover{
            border: 1px solid $colour-border;
        }
    }
  }
  &.img-dropdown--open{
    background-color: $light-grey;
    .img-dropdown__options{
      visibility: visible;
      opacity: 1;
    }
  }
}

.create-home{
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  &__buttons{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 700px;
  }
  &__copy{
    width: 100%;
    text-align: left;
    margin: 0 15px;
    h2{
      color: $color-d-blue;
    }
    p{
      font-size: 2rem;
      line-height: 145%;
      font-weight: 300;
      margin-bottom: 2rem;
      margin-top: 3rem;
      strong{
        font-weight: 800;
      }
    }
  }
  .page-button img{
    width: 150px;
    height: 150px;
  }
}

.page-button{
  width: calc(50% - 30px);
  max-width: 350px;
  height: 320px;
  background: $light-grey;
  margin: 15px;
  padding: 15px;
  overflow: hidden;
  text-align: center;
  display: flex;
  flex-wrap:nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @include transition(0.3s);
  transform: scale(1);
  &:hover{
    transform: scale(0.98);
  }
  img{
    margin: 30px auto;
    width: 80px;
    height: 80px;
    object-fit: contain;
    display: block;
  }
  p{
    pointer-events: none;
  }
}



.film-strip{
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: $filmstrip-width;
  background-color: $med-grey;
  border: 1px solid $colour-border;
  overflow-y: auto;
  overflow-x: hidden;
  @include removescrollbar();
  .film-strip__inner{
    text-align: center;
    padding: 20px 10px 50px;
    min-height: 100%;
    // transition: background 0.3s ease;

  }
  .film-strip__item{
    margin: 0 auto 50px;
    padding: 10px;
    width: 100%;
    height: 100px;
    border: 1px solid $colour-border;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    // transition: background 0.3s ease;
  }
  .film-strip__number{
    position: absolute;
    top: calc(100% + 0.8rem);
    left: 0;
    width: 100%;
    margin: 0;
    color: white;
  }
  .film-strip__content{
    margin:0;
    font-size: 0.8rem;
    
  }
}

.preview{
  .text-box{
    margin: 20px;
    margin-left: 0;
    z-index: 1;
    width: 100%;
    text-align: left;
    white-space: pre-wrap;
    p{
        font-size: 38px;
        line-height: 1.4;
        font-weight: 700;
    }
  }
}