$colour-border: #828282;

.preview--image{
    // border: 1px solid black;
    height: 100%;
    max-height: 550px;
    width: 100%;
    max-width: 775.5px;
  background: white;
  h2{
      font-size: 35pt;
  }
  .left-col{
      width: 50%;
      height: 100%;
      display: flex;
      float: left;
      flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
  }
  .right-col{
    width: 50%;
    height: 100%;
    display: flex;
    float: right;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    position: relative;
    padding: 20px;

    &:before{
        background: #c6e7ea;
        width: calc(100% - 15px);
        height: calc(100% - 30px);
        top: 15px;
        left: 0;
        content:'';
        position: absolute;
        z-index: 0;
    }
}
}

.image-box{
    width: 100%;
    height: 100%;
    position: relative;
    padding:15px;
    img{
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
}